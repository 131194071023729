<template>
  <div class="outer-wrapper">
    <article class="dennis">
      <ul>
        <li>
          <div class="dennis-logo pull-left">
            <a href="/conclusion/risk_l">
              <img :src="contentSrc.risk" />
              <div class="more">风险点数据库</div>
            </a>
          </div>
          <div
            class="dennis-descr pull-right"
            style="font-size: 14px;"
          >结合历史评价线路的所有风险数据进行归类总结，并追踪整改情况，从而不断提升运营能力......</div>
          <div class="clearfix"></div>
        </li>
        <li>
          <div class="dennis-logo pull-right">
            <a href="/conclusion/report_l">
              <img :src="contentSrc.report" />
              <div class="more">安评报告查询</div>
            </a>
          </div>
          <div
            class="dennis-descr pull-right"
            style="font-size: 14px;"
          >评价过程历时4个月，对轨道交通八大系统全面专业的进行运营安全评价，最终出具八份专业报告和一份总报告......</div>
          <div class="clearfix"></div>
        </li>
        <li>
          <div class="dennis-logo pull-left">
            <a href="/period/accident_l">
              <img :src="contentSrc.accident" />
              <div class="more">动态风险管控</div>
            </a>
          </div>
          <div
            class="dennis-descr pull-right"
            style="font-size: 14px;"
          >对减少轨道交通风险的生成，了解故障隐患和风险的相关关系，从而更好的预防风险再次发生......</div>
          <div class="clearfix"></div>
        </li>
      </ul>
    </article>
    <!--<article class='advantage'>
                        <section style="width:65%;" class="pull-left">
                            <img :src='advantageSrc.a1'>
                        </section>
                        <section style='width:33%;' class="pull-right">
                                <img :src='advantageSrc.a2'>
                        </section>
            
                        <section  style="width:35.5%;margin-right:2%;" class="pull-left">
                                <img :src='advantageSrc.a3'>
                        </section>
                        <section style="width:27.5%;" class="pull-left">
                                <img :src='advantageSrc.a4'>
                        </section>
                        <section style="width:33%;" class="pull-right">
                                <img :src='advantageSrc.a5'>
                        </section>
            
                        <section style="width:35.5%;" class="pull-left">
                                <img :src='advantageSrc.a6'>
                        </section>
                        <section style="width:62.5%;" class="pull-right">
                                <img :src='advantageSrc.a7'>
                        </section>
                        <div class="clearfix"></div>
    </article>-->
  </div>
</template>
<script>
let authData = JSON.parse(localStorage.getItem('authData'));

export default {
  name: "indexContent",
  data() {
    return {
      contentItems: [
        {
          imgUrl: process.env.BASE_URL + "img/risk.jpg",
          theSrc: "/risk_nav",
          title: "风险点数据库",
          description:
            "我们对轨道交通运营过程中可能发生的风险进行发现、评价、归类、总结，方便运营方按照相应风险点进行整改，不断的提升运营能力，将可能的运营风险都排除"
        },
        {
          imgUrl: process.env.BASE_URL + "img/report.jpg",
          theSrc: "/conclusion/report_l",
          title: "安评报告查询",
          description:
            "对于每条正在运营的轨道交通线路，每隔一定周期都应进行全面的安全评价，相关专家的工作将会对安全运营起到良好的保障作用"
        },
        {
          imgUrl: process.env.BASE_URL + "img/accident.jpg",
          theSrc: "/conclusion/accident_l",
          title: "动态风险管控",
          description:
            "虽然对风险进行了预估，并不断改进运营管理能力，但已发生的故障才是更加准确的未来大概率会变为现实的风险；对已发生的故障进行分析，可以更好预防相同或类似的故障再次发生"
        }
      ],
      contentSrc: {
        risk: process.env.BASE_URL + "img/risk.jpg",
        report: process.env.BASE_URL + "img/report.jpg",
        accident: process.env.BASE_URL + "img/accident.jpg"
      },
      advantageSrc: {
        a1: process.env.BASE_URL + "img/advantage1.png",
        a2: process.env.BASE_URL + "img/advantage2.png",
        a3: process.env.BASE_URL + "img/advantage3.png",
        a4: process.env.BASE_URL + "img/advantage4.png",
        a5: process.env.BASE_URL + "img/advantage5.png",
        a6: process.env.BASE_URL + "img/advantage6.png",
        a7: process.env.BASE_URL + "img/advantage7.png"
      }
    };
  },
  methods: {
    link: function(value) {
      location.href = value;
    }
  },
  mounted(){
    if(authData.barRights.indexOf('baseInfo')===-1){
                            // alert(response.data.barRights[1]);
                            this.$router.push({ name: authData.barRights[1]});
                            
                        }
  }
};
</script>
<style scoped>
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Microsoft YaHei";
  font-size: 12px;
}

.outer-wrapper {
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clearfix {
  clear: both;
}
ul > li {
  list-style: none;
}
a {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}
img {
  max-width: 100%;
}

.dennis {
  padding-top: 30px;
}
.dennis-logo {
  width: 33%;
  position: relative;
  cursor: pointer;
}

.dennis-logo > a > .more:hover {
  color: #fff;
}

.dennis-logo:hover .more {
  color: #fff;
}

.dennis-logo:hover + .dennis-descr {
  color: #222;
}
.dennis-logo > a > .more {
  color: #ccc;
  font-size: 16px;
  letter-spacing: 1.25px;
  position: absolute;
  top: 10px;
  right: -100px;
  padding: 20px 60px 20px 20px;
  box-sizing: border-box;
  width: 203px;
  cursor: pointer;
  top: 30px;
  background: url("../../assets/more.png") 80% center no-repeat;
  background-color: #9d1f25;
  background-size: 20px;
  font-weight: 700;
}
.dennis-descr {
  position: absolute;
  color: #777;
  width: 45%;
  bottom: 24%;
}
.dennis > ul > li {
  position: relative;
}
.dennis > ul > li:nth-child(1) .dennis-descr {
  right: 200px;
  font-size: 16px;
}

.dennis > ul > li:nth-child(2) .dennis-descr {
  left: 200px;
  font-size: 16px;
}
.dennis > ul > li:nth-child(3) .dennis-descr {
  right: 200px;
}
.dennis > ul > li:nth-child(2) .dennis-logo > a > .more {
  text-align: right;
  left: -100px;
  width: 203px;
  padding: 20px 20px 20px 60px;
  background: #9d1f25 url("../../assets/more1.png") 15% center no-repeat;
  background-size: 20px;
}

.cover-div {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #333333;
  top: 0;
  left: 0;
  z-index: 9999999999;
  filter: alpha(opacity: 50);
  opacity: 0.8;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
}

.link-button {
  width: 200px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 100px;
  right: 0;
  background-color: rgb(32, 102, 167);
  text-align: center;
  color: rgb(250, 249, 249);
  font-size: 20px;
  font-family: SimHei;
  font-weight: 700;
  border-top: 1px solid #1971a8;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.light {
  cursor: pointer;
  position: absolute;
  left: -180px;
  top: 0;
  width: 180px;
  height: 50px;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  transform: skewx(-25deg);
  -o-transform: skewx(-25deg);
  -moz-transform: skewx(-25deg);
  -webkit-transform: skewx(-25deg);
}

.link-button:hover .light {
  left: 180px;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.link-index-button {
  left: 210px;
}

.link-explorer-button {
  right: 210px;
}

.img-wrapper {
  float: left;
  width: 30%;
  margin: 0 1.6%;
}

.img-wrapper:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  transform: translate3d(0, -3px, 0);
}

.navImg {
  width: 100%;
}

.description-wrapper {
  height: 300px;
  background-color: #fff;
}

.title-wrapper {
  font-size: 17px;
  color: #000000;
  text-align: center;
  padding: 50px 0 30px 0;
  margin-top: -5px;
  cursor: pointer;
}

.title-down-border {
  width: 50px;
  height: 3px;
  background-color: #f1f1f1;
  margin: 0 auto;
}

.description-inner-wrapper {
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  color: #999999;
  padding: 0 30px;
  line-height: 2em;
}

.img-wrapper:hover .description-inner-wrapper {
  color: #222;
}

.img-wrapper:hover .title-wrapper {
  color: red;
}

.img-wrapper:hover .title-down-border {
  background-color: red;
}

.title-button {
  border: 1px solid #cecece;
  width: 170px;
  margin: 0 auto;
  padding: 5px 20px;
}

.advantage {
  padding-top: 30px;
}
.advantage > section {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
</style>